<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('leavForm')" @cancel="cancel"></add-header>
    <el-form ref="leavForm" :model="leavForm" :rules="rules" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="单据类型" prop="document_type" required>
            <el-select v-model="leavForm.document_type" placeholder="请选择单据类型" @change="getDocType" clearable>
              <el-option label="请假单" :value="0"></el-option>
              <el-option label="调休单" :value="1"></el-option>
              <el-option label="外勤/出差" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="leavForm.document_type === 2 ? '类型' : '请假类型'" prop="leav_type" required>
            <el-select v-model="leavForm.leav_type" placeholder="请选择" @change="getLeavType" clearable>
              <el-option v-for="item in leavTypeGroupOpt" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时段类型" prop="leav_way" required>
            <el-select v-model="leavForm.leav_way" placeholder="请选择时段类型" clearable @change="changeLeavWay">
              <el-option v-for="item in leavWayGroupOpt" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始日期" prop="leavStartDate">
            <template>
              <el-date-picker
                v-model="leavForm.leavStartDate"
                type="date"
                placeholder="请选择开始日期"
                :picker-options="pickerOptionsStart"
                :disabled="dayDisabled"
                @change="checkStartDate"
              >
              </el-date-picker>
            </template>
          </el-form-item>
          <el-form-item label="开始半天" prop="leav_start_day" :rules="[{ required: !disabled00 }]">
            <el-select v-model="leavForm.leav_start_day" :disabled="disabled00" placeholder="请选择起始半天时段">
              <el-option label="上午" :value="0"></el-option>
              <el-option label="下午" :value="0.5"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :rules="leavForm.leav_way === 0 ? [{ required: false }] : rules.leav_start_time" label="开始时间" prop="leav_start_time">
            <template>
              <el-time-picker
                placeholder="请选择开始时间"
                v-model="leavForm.leav_start_time"
                format="HH:mm"
                value-format="HH:mm"
                :picker-options="{
                  selectableRange: ['00:00:00 - 11:30:00', '13:00:00 - 23:59:59']
                }"
                :disabled="hourDisabled"
                @change="value => mathHourLeav(value, 1)"
              >
              </el-time-picker>
            </template>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="剩余年假(天)" prop="stff_off_break ">
            <el-input v-model="leavForm.stff_off_break" disabled placeholder="根据数据自动填充"></el-input>
          </el-form-item>
          <el-form-item label="剩余调休(时)" prop="stff_off_hour">
            <el-input v-model="stffForm.stff_off_hour" disabled placeholder="根据数据自动填充"></el-input>
          </el-form-item>
          <el-form-item label="剩余旅游假(天)" prop="stff_tour_break">
            <el-input v-model="stffForm.stff_tour_break" disabled placeholder="根据数据自动填充"></el-input>
          </el-form-item>
          <el-form-item label="结束日期" prop="leavEndDate">
            <template>
              <el-date-picker
                v-model="leavForm.leavEndDate"
                type="date"
                placeholder="请选择结束日期"
                :picker-options="pickerOptionsEnd"
                :disabled="dayDisabledEnd"
                @change="checkStartDate"
              >
              </el-date-picker>
            </template>
          </el-form-item>
          <el-form-item label="结束半天" prop="leav_end_day" :rules="[{ required: !disabled00 }]">
            <el-select v-model="leavForm.leav_end_day" :disabled="disabled00" placeholder="请选择结束半天时段">
              <el-option label="上午" :value="0.5"></el-option>
              <el-option label="下午" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="结束时间" prop="leav_end_time" :rules="leavForm.leav_way === 0 ? [{ required: false }] : rules.leav_end_time">
            <template>
              <el-time-picker
                placeholder="请选择结束时间"
                v-model="leavForm.leav_end_time"
                format="HH:mm"
                value-format="HH:mm"
                :picker-options="{
                  selectableRange: timeArray
                }"
                :disabled="hourDisabled"
                @change="mathHourLeav"
              >
              </el-time-picker>
            </template>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="申请员工" prop="leav_stff_name" required>
            <select-input
              :selTableData="selTableData"
              :tableHeader="staffTableHeader"
              @getSelTableRow="getSelTableRow"
              @openSelect="openSelect"
              :inputVal="leavForm.leav_stff_name"
              :disabled="disabled"
              :isShowCol="false"
              :rowSelect="rowSelect"
              placeholder="请选择员工姓名"
            >
            </select-input>
          </el-form-item>
          <el-form-item label="员工公司" prop="cptt_name">
            <el-input v-model="stffForm.cptt_name" disabled placeholder="根据所选员工自动填充"></el-input>
          </el-form-item>
          <el-form-item label="员工部门" prop="dept_name">
            <el-input v-model="stffForm.dept_name" disabled placeholder="根据所选员工自动填充"></el-input>
          </el-form-item>
          <el-form-item label="员工小组" prop="dept_team_name">
            <el-input v-model="stffForm.dept_team_name" disabled placeholder="根据所选员工自动填充"></el-input>
          </el-form-item>
          <el-form-item :label="labelDay" prop="leav_day_diff">
            <el-input v-model="leavForm.leav_day_diff" disabled placeholder="根据请假时间自动填充1"></el-input>
          </el-form-item>
          <el-form-item :label="labelHour" prop="leav_hour_diff">
            <el-input v-model="leavForm.leav_hour_diff" disabled placeholder="根据请假时间自动填充"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="leavForm.leav_remark" :rows="1" maxlength="100" placeholder="请填写备注" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row class="vg_mt_16">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { stffAPI } from '@api/modules/staff';
import { leavAPI } from '@api/modules/leav';
import inputUser from '@/views/component/inputUser';
import addHeader from '@/views/component/addHeader';
import selectInput from '@/views/component/selectInput';
import { staffTableHeader } from '@/views/component/tableHeader/staffTableHeader';
import options from '@/views/component/common/options';
import Login from '@/views/Login';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'LeavAddMain',
  components: {
    inputUser,
    selectInput,
    addHeader
  },
  data() {
    return {
      leavForm: {
        document_type: null,
        leav_type: null,
        leav_way: null,
        leavStartDate: null,
        leavEndDate: null,
        leav_start_date: null,
        leav_start_day: null,
        leav_start_time: null,
        leav_end_date: null,
        leav_end_day: null,
        leav_end_time: '',
        leav_day_diff: null,
        leav_hour_diff: null,
        stff_off_break: null,
        stff_off_hour: null,
        leav_remark: '',
        leav_stff_id: null,
        leav_dept_id: null,
        leav_dept_team_id: null,
        leav_stff_name: ''
      },
      rowSelect: [],
      rules: {
        leavStartDate: [{ type: 'date', required: true, message: '请选择开始日期', trigger: 'change' }],
        leavEndDate: [{ type: 'date', required: true, message: '请选择结束日期', trigger: 'change' }],
        leav_start_time: [{ required: true, message: '请选择开始时间', trigger: 'blur' }],
        leav_end_time: [{ required: true, message: '请选择结束时间', trigger: 'blur' }]
      },
      stffForm: {
        stff_name: '',
        cptt_name: '',
        dept_name: '',
        dept_team_name: '',
        stff_id: Number,
        cptt_id: Number,
        dept_id: Number,
        dept_team_id: Number,
        stff_this_break: null,
        stff_last_break: null,
        stff_off_hour: null
      },
      selTableData: [],
      staffTableHeader: [],
      leavTypeGroupOpt: [], //请假类型
      hourDisabled: true,
      dayDisabled: false,
      dayDisabledEnd: false,
      disabled00: true,
      disabled: false,
      dateFlag: false, // 是否开放半天
      gapValue: null,
      pickerOptionsStart: {},
      timeArray: ['00:00:00 - 11:30:00', '13:00:00 - 23:59:59'],
      pickerOptionsEnd: {
        disabledDate: time => {
          if (this.leavForm.leavStartDate) {
            return time.getTime() < this.leavForm.leavStartDate;
          }
          // return time.getTime() < Date.now() - 8.64e7; /*今天及以后*/
          return time.getTime() > Date.now() - 8.64e6; /*今天及之前，注意数字不一样*/
        }
      },
      leavWayGroupOpt: [],
      labelDay: '请假天数',
      labelHour: '请假小时',
      stffEnterTime: null
    };
  },
  watch: {
    leavForm: {
      deep: true,
      handler: function (val) {
        if (val.leavStartDate && val.leavEndDate) {
          this.mathDaysLeav();
        }
        if ((val.leav_type === 1 && val.leav_way === 0) || (val.leav_type === 0 && val.leav_way === 0)) {
          this.mathHourLeav();
          // returnthis.$message('请选择开始时间和结束时间')
        }
      }
    },
    'leavForm.leav_way': {
      handler: function (val) {
        if (val === 0) {
          this.hourDisabled = true;
          this.leavForm.leav_hour_diff = null;
        }
      }
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getLeavType();
      this.staffTableHeader = staffTableHeader;
      let params = JSON.parse(UrlEncode.decode(this.$route.query.key));
      // if (params.personal === 1) {
      //   this.disabled = false;
      // }
      this.getPersonal();
    },
    // params.personal === 1 是个人进来 员工不可更换
    getPersonal() {
      //stff_name 申请员工
      const userInfo = this.$cookies.get('userInfo');
      get(stffAPI.getMyStff)
        .then(res => {
          if (res.data.code === 0) {
            const form = res.data.data.form;
            this.stffForm.cptt_name = form.cptt_name; //公司信息
            this.stffForm.stff_off_hour = form.stff_off_hour; //剩余年假
            this.stffForm.stff_tour_break = form.stff_tour_break; //剩余旅游年假
            // this.leavForm.stff_off_break = form.stff_this_break + form.stff_last_break//剩余年假
            this.stffForm.cptt_id = form.cptt_id;
            this.stffForm.dept_id = form.dept_id;
            this.stffForm.dept_team_id = form.dept_team_id;
            this.stffForm.stff_id = form.stff_id;
            this.stffForm.dept_team_name = form.dept_team_name;
            this.stffEnterTime = Number(res.data.data.form.stff_enter_time) * 1000;
            let num = new Date(this.stffEnterTime).getFullYear();
            let newDate = new Date().getTime();
            let daysInAYear = 0;
            if (num % 4 === 0) {
              daysInAYear = 366 * 24 * 60 * 60 * 1000;
            } else {
              daysInAYear = 365 * 24 * 60 * 60 * 1000;
            }
            if (this.stffEnterTime + daysInAYear < newDate) {
              this.leavForm.stff_off_break = form.stff_this_break + form.stff_last_break;
            } else {
              this.leavForm.stff_off_break = 0;
            }
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
      this.leavForm.leav_stff_name = userInfo.stff_name; //员工姓名
      this.stffForm.dept_name = userInfo.dept_name; //员工部门
    },
    // 获取传递selTableData
    openSelect(val) {
      if (val === true) {
        this.getSelectTable();
      }
    },
    getSelectTable() {
      get(stffAPI.getAllStffsV2).then(res => {
        if (res.data.code === 0) {
          this.selTableData = res.data.data;
        }
      });
    },
    // 获取双击selTableData的row
    getSelTableRow(val) {
      this.stffForm = val;
      this.leavForm.leav_stff_id = this.stffForm.stff_id;
      this.leavForm.leav_stff_name = this.stffForm.stff_name;
      this.getStffInfo(val.stff_id);
    },
    //获取表单信息
    getStffInfo(val) {
      get(stffAPI.getStffById, { stff_id: val })
        .then(res => {
          if (res.data.code === 0) {
            this.stffEnterTime = Number(res.data.data.form.stff_enter_time) * 1000;
            let num = new Date(this.stffEnterTime).getFullYear();
            let newDate = new Date().getTime();
            let daysInAYear = 0;
            if (num % 4 === 0) {
              daysInAYear = 366 * 24 * 60 * 60 * 1000;
            } else {
              daysInAYear = 365 * 24 * 60 * 60 * 1000;
            }
            if (this.stffEnterTime + daysInAYear < newDate) {
              this.leavForm.stff_off_break = this.stffForm.stff_this_break + this.stffForm.stff_last_break;
            } else {
              this.leavForm.stff_off_break = 0;
            }
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 根据单据类型切换请假类型清空选项
    clearTypeInfo() {
      this.leavForm.leav_type = null;
      this.leavForm.leav_way = null;
      this.leavForm.leav_start_day = null;
      this.leavForm.leav_end_day = null;
      this.leavForm.leav_start_day = null;
      this.leavForm.leav_end_day = null;
      this.disabled00 = true;
    },
    // 根据时段类型更新
    clearLeavWay() {
      this.leavForm.leav_start_time = null;
      this.leavForm.leav_end_time = null;
      this.leavForm.leav_hour_diff = null;
    },
    // 根据请假类型切换清空选项
    clearLeavTypeInfo() {
      this.leavForm.leav_start_day = null;
      this.leavForm.leav_end_day = null;
      this.leavForm.leav_hour_diff = null;
      this.leavForm.leav_start_time = null;
      this.leavForm.leav_end_time = null;
      this.leavForm.leav_way = null;
    },
    // 获取请假类型
    getDocType() {
      this.clearTypeInfo();
      if (this.leavForm.document_type === 1) {
        this.labelDay = '增加天数';
        this.labelHour = '增加小时';
        this.leavTypeGroupOpt = options.leavOpts.filter(item => item.value === 0);
      } else if (this.leavForm.document_type === 0) {
        this.labelDay = '请假天数';
        this.labelHour = '请假小时';
        this.leavTypeGroupOpt = options.leavOpts.filter(item => item.value !== 0);
      } else if (this.leavForm.document_type === 2) {
        this.labelDay = '外勤/出差天数';
        this.labelHour = '外勤/出差小时';
        this.leavTypeGroupOpt = options.leavOpts.filter(item => [12, 13, 14].includes(item.value));
      }
    },
    // 更换请假类型
    getLeavType(newVal) {
      this.clearLeavTypeInfo();
      if (newVal < 2 || [3, 11, 12, 13, 14].includes(newVal)) {
        this.leavForm.leav_way = null;
        this.leavWayGroupOpt = [
          { value: 0, label: '按天数' },
          { value: 1, label: '按小时' }
        ];
      } else if (newVal === 2 || newVal >= 4) {
        this.leavForm.leav_way = 0;
        this.dayDisabledEnd = false;
        this.leavWayGroupOpt = [{ value: 0, label: '按天数' }];
      }
      // (半天等) 年假和丧假处理
      if (newVal === 2 || newVal === 7) {
        this.dateFlag = true;
        this.leavForm.leav_start_day = 0;
        this.leavForm.leav_end_day = 1;
        this.disabled00 = false;
        this.hourDisabled = true;
      } else {
        this.dateFlag = false;
        this.disabled00 = true;
      }
      //病假可以选择半天
      if (newVal === 4) {
        this.dateFlag = true;
        this.disabled00 = false;
      }
      // // 增加调休算小时
      // if(newVal === 0){
      //
      // }
    },
    // 切换类型(时段类型)
    changeLeavWay(val) {
      // this.leavForm.leav_hour_diff = null
      if (val === 1) {
        this.hourDisabled = false;
        this.dayDisabledEnd = true;
        this.leavForm.leavEndDate = this.leavForm.leavStartDate;
      } else if (val === 0) {
        this.hourDisabled = true;
        this.dayDisabledEnd = false;
        this.clearLeavWay();
      }
    },
    // 选择开始日期 调休单，结束日期等于开始日期
    checkStartDate(newVal) {
      if (this.leavForm.leav_way === 1) {
        this.leavForm.leavEndDate = newVal;
        this.mathDaysLeav();
      }
      let strTime = this.leavForm.leavStartDate ? this.leavForm.leavStartDate.getTime() : '';
      let endTime = this.leavForm.leavEndDate ? this.leavForm.leavEndDate.getTime() : '';
      if (strTime && endTime) {
        if (strTime > endTime) {
          this.leavForm.leavEndDate = '';
          this.$message.warning('开始日期不可早于结束日期');
        }
      }
    },
    // 计算天数差
    mathDaysLeav() {
      if (this.dateFlag === true) {
        const leavDate = new Date(this.leavForm.leavEndDate) - new Date(this.leavForm.leavStartDate);
        const leavDays = Math.floor(leavDate / (24 * 3600 * 1000)) - (this.leavForm.leav_start_day - this.leavForm.leav_end_day);
        this.leavForm.leav_day_diff = leavDays;
      } else {
        const leavDate = new Date(this.leavForm.leavEndDate) - new Date(this.leavForm.leavStartDate);
        const leavDays = Math.floor(leavDate / (24 * 3600 * 1000)) + 1;
        this.leavForm.leav_day_diff = leavDays;
      }
    },
    // 计算小时差
    mathHourLeav(e, val) {
      if (this.leavForm.leav_start_time) {
        if (this.leavForm.leav_start_time === '11:30') {
          this.timeArray = ['13:01:00 - 23:59:59'];
        } else if (this.leavForm.leav_start_time === '11:29') {
          this.timeArray = ['11:30:00 - 11:30:00', '13:00:00 - 23:59:59'];
        } else {
          this.timeArray = [this.leavForm.leav_start_time + ':00 - 11:30:00', '13:00:00 - 23:59:59'];
        }
        if (val === 1) {
          this.leavForm.leav_end_time = null;
        }
      }
      if (this.leavForm.leav_start_time && this.leavForm.leav_end_time) {
        let shour = Number(this.leavForm.leav_start_time.split(':')[0]);
        let ehour = Number(this.leavForm.leav_end_time.split(':')[0]);
        let sminu = Number(this.leavForm.leav_start_time.split(':')[1]);
        let eminu = Number(this.leavForm.leav_end_time.split(':')[1]);
        const st = parseInt((shour + sminu / 60) * 10000);
        const en = parseInt((ehour + eminu / 60) * 10000);
        const diff = (en - st) / 10000;
        this.gapValue = Math.ceil(diff * 2) / 2;
        // this.gapValue = Math.ceil(((Number(ehour) + (Number(eminu) / 60)) - (Number(shour) + (Number(sminu) / 60)))*2)/2
        if (shour < 12 && ehour >= 13) {
          this.gapValue = this.gapValue - 1.5;
        }
        if (this.leavForm.leav_type === 0 && Math.abs(diff) % 0.5 !== 0) {
          this.gapValue = this.gapValue - 0.5;
        }
        // if(this.leavForm.leav_type === 0 && (Number(eminu)-Number(sminu))%30!==0) {
        //   this.gapValue = this.gapValue - 0.5
        // }
        // if (this.leavForm.leav_type === 0 || this.leavForm.leav_type === 1) {
        //   if (this.gapValue >= 7) {
        //     this.gapValue = 8;
        //   }
        // }
        if (this.leavForm.document_type === 0 && this.leavForm.leav_type === 1 && this.leavForm.leav_way === 1) {
          let isOne = this.gapValue < 1;
          if (isOne) this.$message.warning('调休时间起始不能小于一小时!');
          this.leavForm.leav_hour_diff = isOne ? 1 : this.gapValue;
        } else {
          this.leavForm.leav_hour_diff = this.gapValue;
        }
      } else if (this.leavForm.leavStartDate && this.leavForm.leavEndDate) {
        this.leavForm.leav_hour_diff = this.leavForm.leav_day_diff * 8;
      }
    },
    //提交表单
    submit(formName) {
      if (this.leavForm.leav_type === 1 && Number(this.stffForm.stff_off_hour) === 0) return this.$message.warning('剩余调休不足');
      if (this.leavForm.leav_type === 2 && Number(this.leavForm.stff_off_break) === 0) return this.$message.warning('剩余年假不足');
      if (this.leavForm.leav_type === 2 && Number(this.leavForm.stff_off_break) < Number(this.leavForm.leav_day_diff))
        return this.$message.warning('剩余年假不足');
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              if (this.leavForm.leav_type == 10) {
                if (this.stffForm.stff_tour_break < this.leavForm.leav_day_diff) {
                  this.$message({ type: 'warning', message: '您选择的时间超过所剩的旅游假时间' });
                  return false;
                }
              }
              if (this.leavForm.leav_start_time == null && this.leavForm.leav_way == 1) {
                this.$message({ type: 'warning', message: '请选择开始时间和结束时间' });
                return false;
              }
              if (this.leavForm.leav_way == 0 && this.leavForm.leav_day_diff == 0) {
                this.$message({ type: 'warning', message: '请正确选择时间段' });
                return;
              }
              if (this.leavForm.leav_hour_diff <= 0 && this.leavForm.leav_way == 1) {
                this.$message({ type: 'warning', message: '请假时间不可以小于半个小时' });
                return false;
              }
              if (this.leavForm.leav_type === 2) {
                if (this.leavForm.leav_start_day === null || this.leavForm.leav_end_day === null) {
                  let mg = '年假请选择半天';
                  let tp = 'warning';
                  this.$message({ message: mg, type: tp });
                  return false;
                }
                if (this.leavForm.leav_day_diff > this.leavForm.stff_off_break) {
                  let mg = '请假天数不能超过剩余年假天数';
                  let tp = 'warning';
                  this.$message({ message: mg, type: tp });
                  return false;
                }
              } else if (this.leavForm.leav_type === 1) {
                if (this.leavForm.leav_way === 0) {
                  if (this.leavForm.leav_hour_diff > this.stffForm.stff_off_hour) {
                    let mg = '请假小时不能超过剩余调休小时';
                    let tp = 'warning';
                    this.$message({ message: mg, type: tp });
                    return false;
                  }
                } else if (this.leavForm.leav_way === 1) {
                  if (this.leavForm.leav_hour_diff >= 7) {
                    this.$set(this.leavForm, 'leav_hour_diff', 8);
                  }
                  if (this.leavForm.leav_hour_diff > this.stffForm.stff_off_hour) {
                    let mg = '请假小时不能超过剩余调休小时';
                    let tp = 'warning';
                    this.$message({ message: mg, type: tp });
                    return false;
                  }
                }
              } else if (this.leavForm.leav_type === 6 || this.leavForm.leav_type === 9) {
                if (this.leavForm.leav_day_diff > 10) {
                  let mg = '婚假与陪产假不能超过10天';
                  let tp = 'warning';
                  this.$message({ message: mg, type: tp });
                  return false;
                }
              }
              // else if (this.leavForm.leav_type === 0 && this.leavForm.leav_way === 1) {
              //   if (this.leavForm.leav_hour_diff >= 7) {
              //     this.leavForm.leav_hour_diff = 8;
              //   }
              // }
              if (this.leavForm.leav_type !== 2 && this.leavForm.leav_type !== 4 && this.leavForm.leav_type !== 7) {
                this.leavForm.leav_start_day = null;
                this.leavForm.leav_end_day = null;
              }
              this.saveInfo();
            } else {
              // this.$message({message:'请填写开始时间和结束时间'});
              // console.log('error submit!');
              if (this.leavForm.leav_stff_name === '') {
                let mg = '请选择申请员工';
                let tp = 'warning';
                this.$message({ message: mg, type: tp });
              }
              return false;
            }
          });
        })
        .catch(() => {
          this.$message.info('已取消保存');
        });
    },
    // 清空stffForm
    resForm() {
      this.stffForm.cptt_name = null;
      this.stffForm.dept_name = null;
      this.stffForm.dept_team_name = null;
      this.stffForm.stff_off_hour = null;
      this.initData();
    },
    // 保存
    saveInfo() {
      const inputUser = this.$refs.userData.getData();
      const leavFormBody = Object.assign(this.leavForm, inputUser);
      leavFormBody.leav_stff_id = this.stffForm.stff_id;
      leavFormBody.leav_cptt_id = this.stffForm.cptt_id;
      leavFormBody.leav_dept_id = this.stffForm.dept_id;
      leavFormBody.leav_dept_team_id = this.stffForm.dept_team_id;
      leavFormBody.leav_start_date = Number(new Date(this.leavForm.leavStartDate).getTime() / 1000);
      leavFormBody.leav_end_date = Number(new Date(this.leavForm.leavEndDate).getTime() / 1000);
      (leavFormBody.status = 0), (leavFormBody.personal = this.$cookies.get('userInfo').personal);
      post(leavAPI.addLeav, leavFormBody)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.resetForm('leavForm');
            this.resForm();
            const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
            this.jump('/leav_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: props.perm_id,
                  form_id: res.data.data.form_id
                })
              )
            });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('leavForm');
          this.resForm();
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped></style>

<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 />
      </el-tab-pane>
      <!-- <el-tab-pane label="相关文档" name="second" :key="'second'">
        <childTab2/>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import LeavAddMain from '@/views/OfficeManagement/LeavManage/TabChild/LeavAddMain';
// import LeavAddAbout from "@/views/OfficeManagement/LeavManage/TabChild/LeavAddAbout";
export default {
  name: 'LeavAdd',
  components: {
    childTab1: LeavAddMain
    // childTab2: LeavAddAbout
  },
  data() {
    return {
      activeName: 'first'
    };
  }
};
</script>

<style scoped></style>

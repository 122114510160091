<template>
  <div class="vd_login">
    <div ref="main" class="vd_main">
      <div class="vd_main_box">
        <div class="header"></div>
        <el-row class="vd_card_o">
          <el-card :body-style="{ padding: '0px' }" class="vd_card">
            <el-row>
              <el-col :span="12" class="vd_bg">
                <div class="vd_inner_box">
                  <el-row class="vd_word vd_inner_title">
                    <span class="vd_text">
                      <h2>上海满恩ERP系统</h2>
                    </span>
                  </el-row>
                  <el-row class="vd_word vd_inner_content">
                    <span class="vd_text"> 上海满恩计算机科技有限公司版权所有</span>
                  </el-row>
                </div>
              </el-col>
              <el-col :span="12">
                <div v-show="isLogin">
                  <div class="vd_center vd_title">欢迎登录</div>
                  <el-form ref="login" :model="loginForm">
                    <el-row>
                      <el-col class="vg_mt_16 vd_user_input">
                        <el-form-item prop="user_name">
                          <el-input
                            v-model="loginForm.user_name"
                            show-word-limit
                            @input="loginForm.user_name = helper.keepEngNum(loginForm.user_name)"
                            maxlength="16"
                            placeholder="请输入用户名（仅限英文和数字）"
                            class="vd_login_input"
                          >
                            <i slot="prefix" class="el-input__icon el-icon-user-solid"></i>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col class="vg_mt_16 vd_user_input">
                        <el-form-item prop="user_pass">
                          <el-input
                            v-model="loginForm.user_pass"
                            minlength="6"
                            maxlength="16"
                            placeholder="请输入密码(首次登陆输入初始密码）"
                            class="vd_login_input"
                            show-password
                            clearable
                          >
                            <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                  <div class="vd_center vg_mt_16">
                    <el-button type="primary" class="vd_login_button" @click="changePassword">登录</el-button>
                  </div>
                </div>
                <div v-show="isChange">
                  <div class="vd_center vd_title">重置初始密码</div>
                  <el-form ref="changeForm" :model="changeForm" :rules="rules" label-width="156px">
                    <el-row>
                      <el-col class="vg_mt_16">
                        <el-form-item label="密码" prop="user_pass">
                          <el-input
                            v-model="changeForm.user_pass"
                            minlength="6"
                            maxlength="16"
                            placeholder="新密码必须包含字母和数字"
                            class="vd_login_input"
                            show-password
                            clearable
                          >
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col class="vg_mt_16">
                        <el-form-item label="确认密码" prop="user_pass2">
                          <el-input
                            v-model="changeForm.user_pass2"
                            minlength="6"
                            maxlength="16"
                            placeholder="请确认新密码"
                            class="vd_login_input"
                            show-password
                            clearable
                            @keyup.enter.native="submit('changeForm')"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                  <div class="vd_center">
                    <el-button type="primary" class="vd_login_button" @click="submit('changeForm')">确认修改</el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>
          <div class="foots">
            <div @click="jumpG" class="vg_ml_8 vg_cursor">沪ICP备16001718号-6</div>
            <div>All Rights Reserved by Fantastic Pet © 2022</div>
          </div>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { userAPI } from '@api/modules/user';
import { debounce } from 'lodash';

export default {
  name: 'Login',
  data() {
    let checkPwd = (rule, value, callback) => {
      const pwdReg = /^[\d\S]{6,16}$/;
      if (!value) {
        return callback(new Error('密码不能为空'));
      } else {
        if (pwdReg.test(value)) {
          callback();
        } else {
          callback(new Error('请输入正确的格式,密码长度为6-16'));
        }
      }
    };
    //验证密码
    let checkPwd2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请确认密码'));
      } else if (value !== this.changeForm.user_pass) {
        callback(new Error('两次密码不一致'));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        user_name: '',
        user_pass: ''
      },
      changeForm: {
        user_id: null,
        user_pass: '',
        user_pass2: ''
      },
      isLogin: true,
      isChange: false,
      rules: {
        user_pass: [{ required: true, validator: checkPwd, trigger: 'blur' }],
        user_pass2: [{ required: true, validator: checkPwd2, trigger: 'blur' }]
      },
      userInfo: {},
      push: [],
      listener: ''
    };
  },
  created() {
    this.getVersion();
  },
  mounted() {
    document.onkeydown = ({ key }) => (key === 'Enter' ? this.changePassword() : '');
  },
  destroyed() {
    document.onkeydown = '';
  },
  methods: {
    getVersion() {
      get(userAPI.getVersion)
        .then(res => {
          if (res.data.code === 0) {
            if (localStorage.getItem('loaded') !== res.data.data.version + '') {
              localStorage.setItem('loaded', res.data.data.version + '');
              window.location.reload();
            }
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    //跳转修改密码
    changePassword: debounce(function () {
      post(userAPI.userLogin, this.loginForm)
        .then(res => {
          if (res.data.code === 0) {
            this.userInfo = res.data.data.user;
            this.push = res.data.data.push;
            if (this.userInfo.update_time === 0) {
              // 初次登录
              this.$message({
                type: 'success',
                message: '请修改初始密码'
              });
              this.isLogin = false;
              this.isChange = true;
            } else {
              // 正常跳转首页
              this.$cookies.set('userInfo', this.userInfo, 60 * 60 * 24);
              this.$cookies.set('push', JSON.stringify(this.push), 60 * 60 * 24);
              this.jump('/dashboard');
            }
          } else if (res.data.code === 4) {
            let mg = '请重新点击登录';
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.deleteCookie();
          } else {
            //登陆不成功
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.deleteCookie();
          }
        })
        .catch(() => {
          this.deleteCookie();
        });
    }, 500),
    // 删除cookie
    deleteCookie() {
      get(userAPI.userLogout)
        .then(res => {
          if (res.data.code === 0) {
            this.$cookies.remove('userInfo');
            this.$cookies.remove('push');
          }
        })
        .catch(() => {
          this.$cookies.remove('userInfo');
          this.$cookies.remove('push');
        });
    },
    //提交表单
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.doLogin();
        } else {
          console.log('error submit!');
          return false;
        }
      });
    },
    jumpG() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index');
    },
    //真·登录
    doLogin() {
      this.changeForm.user_id = this.userInfo.user_id;
      post(userAPI.resetUserPass, this.changeForm).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '修改成功，请重新登陆'
          });
          this.doLogout();
          // this.isChange = false
          // this.isLogin = true
          // this.loginForm.user_pass = ''
        }
      });
    },
    doLogout() {
      get(userAPI.userLogout)
        .then(res => {
          if (res.data.code === 0) {
            this.isChange = false;
            this.isLogin = true;
            this.loginForm.user_pass = '';
          }
        })
        .catch(() => {
          this.deleteCookie();
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_login {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.vd_main {
  position: relative;
  height: 100vh;
  margin: 0 auto;
}
.vd_card {
  width: 100%;
}
.vd_bg {
  width: 528px;
  height: 511px;
  position: relative;
  background: url('~@/assets/image/bg-img.png') no-repeat center/cover;
}
.vd_inner_box {
  height: 100%;
  background: rgba(64, 158, 255, 0.5);
}
.vd_father {
  display: flex;
  align-items: center;
}
.vd_word {
  width: 100%;
  color: $color-white;
}
.vd_inner_title {
  position: absolute;
  top: 40%;
}
.vd_inner_content {
  position: absolute;
  bottom: 5%;
}
.vd_text {
  display: flex;
  justify-content: center;
}
.vd_center {
  text-align: center;
}
.vd_title {
  font-size: 24px;
  margin-top: 80px;
  margin-bottom: 30px;
  font-weight: 550;
  color: $color_primary;
}
.vd_login_button {
  width: 75%;
  margin-top: 30px;
}
//.vd_user_input {
//  display: flex;
//  justify-content: center;
//}
.vd_login_input {
  width: 60%;
}
.vd_forget {
  margin-top: 16px;
  margin-right: 16px;
  display: -webkit-flex;
  display: flex;
  justify-content: flex-end;
}
.el-input__icon {
  color: $color-primary;
}
.vd_user_input ::v-deep.el-form-item__content {
  display: flex;
  justify-content: center;
}
.header {
  position: absolute;
  width: 100%;
  height: 50%;
  background: #008dbd;
  box-shadow: 0 10px 28px rgb(119, 175, 211);
}
.vd_card_o {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1056px;
}
.foots {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: -50px;
  left: 0;
  box-sizing: border-box;
}
</style>
